import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useAuth from '../hooks/useAuth';
import { PATH_GLOBAL, PATH_AUTH } from '../routes/paths';
// ----------------------------------------------------------------------

export default function Index() {
  const { push } = useRouter();

  const { isAuthenticated, isAdmin, isInitialized, organization } = useAuth();
  useEffect(() => {
    if (isInitialized) {
      if (isAdmin) {
        push(PATH_GLOBAL.organizations.root);
      } else if (isAuthenticated && isAdmin === false && organization) {
        window.location.href = '/app/' + organization + '/me';
      } else {
        push(PATH_AUTH.login);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAdmin, isInitialized]);

  return null;
}
